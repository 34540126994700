(def log! (...args)
     (.for-each (inspect! ..args ) (#> (console.log #0))))

(def inspect! (...args)
     (args.map (#> (util.inspect #0 { colors false depth 3}))))

(def color (code items depth)
     (concat code (join items) "\033[0m"))

(def black (...args) (color "\033[0;30m" args))
(def red (...args) (color "\033[0;31m" args))
(def green (...args) (color "\033[0;32m" args))
(def brown (...args) (color "\033[0;33m" args))
(def blue (...args) (color "\033[0;34m" args))
(def purple (...args) (color "\033[0;35m" args))
(def cyan (...args) (color "\033[0;36m" args))
(def gray (...args) (color "\033[0;37m" args))
(def bold-gray (...args) (color "\033[1;30m" args))
(def bold-red (...args) (color "\033[1;31m" args))
(def bold-green (...args) (color "\033[1;32m" args))
(def yellow (...args) (color "\033[1;33m" args))
(def bold-blue (...args) (color "\033[1;34m" args))
(def bold-purple (...args) (color "\033[1;35m" args))
(def bold-cyan (...args) (color "\033[1;36m" args))
(def white (...args) (color "\033[1;37m" args))
