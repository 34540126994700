(def output-formatter (node)
     (if (list? node)
         (pipe node (map output-formatter) join)

         (node? node 'output)
         (output-formatter node.contents)

         (or (string? node) (number? node)) node

         (not (exists? node)) ""

         (node? node)
         (do
          (console.log ("warning: We ran into an unexpected node that never got transpiled at "node.file":"node.line":"node.col"."))
          (output-formatter (transpile node)))))

(set sibilant 'output-formatter output-formatter)
