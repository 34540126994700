(include
 "./macros/case"
 "./macros/comparison"
 "./macros/flow-control"
 "./macros/hash"
 "./macros/lambda"
 "./macros/lists"
 "./macros/loops"
 "./macros/macros"
 "./macros/math"
 "./macros/misc"
 "./macros/pipe"
 "./macros/predicates"
 "./macros/regex"
 "./macros/switch"
 "./macros/variables"
 )
